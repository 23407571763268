var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Give Induction ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.items,"disabled":!!_vm.induction,"loading":_vm.loadingItems,"label":"Select Induction","item-text":"ref","item-value":"_id","return-object":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getTrainings(item) .map(function (ref) {
	var name = ref.name;

	return name;
}) .join(', '))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getTrainings(item) .map(function (ref) {
	var name = ref.name;

	return name;
}) .join(', '))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}]),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedItem && !_vm.induction),expression:"selectedItem && !induction"}]},[_vm._v(" Once you've gone through the induction with everyone, click Continue. ")])],1),_c('v-card-actions',[(_vm.selectedItem && _vm.selectedItem.checklistUrl)?_c('primary-btn',{attrs:{"href":_vm.selectedItem.checklistUrl,"target":"_blank"}},[_vm._v(" Induction Checklist "),_c('v-icon',{attrs:{"right":"","x-small":""}},[_vm._v("mdi-open-in-new")])],1):_vm._e(),_c('v-spacer'),_c('primary-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedItem && !_vm.induction),expression:"selectedItem && !induction"}],attrs:{"loading":_vm.loadingInduction},on:{"click":_vm.submit}},[_vm._v(" Continue ")])],1),(_vm.url)?_c('v-card-text',{staticClass:"text-center"},[_c('qr',{staticStyle:{"max-width":"300px"},attrs:{"url":_vm.url}}),(_vm.$isDev)?_c('p',[_c('a',{attrs:{"target":"_blank","href":_vm.url}},[_vm._v(_vm._s(_vm.url))])]):_vm._e(),_c('p',[_vm._v("Get everyone that has received the induction to scan this QR code.")]),_c('p',[_vm._v(" If they cannot scan it for whatever reason, please add them below "),_c('br'),_vm._v(" and we will send them an email with the link. ")])],1):_vm._e(),_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(_vm.url),expression:"url"}]},[_c('user-selector',{attrs:{"label":"Manual Inductees","exclude-ids":_vm.excludeIds},model:{value:(_vm.userIds),callback:function ($$v) {_vm.userIds=$$v},expression:"userIds"}}),_c('v-spacer'),_c('primary-btn',{attrs:{"disabled":!_vm.userIds.length,"loading":_vm.loadingInduction},on:{"click":_vm.sendEmail}},[_vm._v(" Send Email ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }