var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"width":"300","permanent":"","app":"","clipped":""}},[_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-wrench")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(" Training Config ")])],1),_c('v-list-item-icon',{staticClass:"ml-2"},[_c('training-add',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onAdd = ref.on;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},Object.assign({}, onAdd, onTooltip)),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add New Config")])])]}}])})],1)],1),_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-database-search")])],1),_c('v-list-item-content',[_c('v-text-field',{attrs:{"label":"Search","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1)],1),_c('v-divider'),_c('v-virtual-scroll',{attrs:{"items":_vm.configs,"height":"calc(100% - 60px - 64px - 1px)","item-height":"49","bench":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item._id,attrs:{"to":("/training-config/" + (item._id))}},[_c('v-list-item-action',{staticClass:"my-0"},[_c('item-mover',{attrs:{"items":_vm.configs,"item":item},on:{"update:items":_vm.updateOrder}})],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({},item.name.length > 30 ? on : undefined),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1),_c('v-divider')]}}])})],1),_c('training-edit',{attrs:{"id":_vm.configId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }