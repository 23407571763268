var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" User Status List "),_c('v-spacer'),_c('v-btn-toggle',{model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('v-btn',{attrs:{"small":"","value":"pending"}},[_vm._v(" Pending ")]),_c('v-btn',{attrs:{"small":"","value":"complete"}},[_vm._v(" Complete ")])],1)],1),_c('v-data-table',{attrs:{"items":_vm.completions.data,"server-items-length":_vm.completions.total,"options":_vm.options,"headers":_vm.headers,"loading":_vm.loading,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUser(item.userId).name)+" ")]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUser(item.userId).username)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.startCase(item.status))+" ")]}},{key:"item.items",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(_vm.getInductionCompletedAtTime(item)))+" ")]}},{key:"item.steps",fn:function(ref){
var item = ref.item;
return [_c('view-items',{attrs:{"id":_vm.trainingId,"user":_vm.getUser(item.userId),"count":""}}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v(" Steps Progress ")]),_c('view-items',{attrs:{"id":_vm.trainingId,"user":_vm.getUser(item.userId),"readonly":""}})],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":("/users/" + (item.userId))}},on),[_c('v-icon',[_vm._v("mdi-account-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }